<template>
  <div class="container">
    <div class="div_logo"><img src="../../static/logo.png" class="logo"></div>
    <div class="content">
      <div class="main-content">
        <div class="input-div">
          <img src="../../static/yonghu.png">
          <hr class="hr-span">
          <input
            v-model="account"
            type="text"
            placeholder="学/工号"
            placeholder-style="color:#BFBDBC;"
          >
          <span v-if="show">请输入学工号</span>
        </div>
        <div class="input-div">
          <img src="../../static/mima.png">
          <hr class="hr-span">
          <input
            id="mima"
            v-model="mima"
            type="password"
            placeholder="密   码"
            placeholder-style="color:#BFBDBC;"
            autocomplete="new-password"
          >
          <img v-if="mimashow" id="mimaeye" src="../../static/beye.png" @click="showmima()">
          <img v-if="!mimashow" id="mimaeye" src="../../static/eye.png" @click="showmima()">
          <span v-if="show2">请输入密码</span>
          <span v-if="msgshow" style="color: red" v-text="msg" />
        </div>

        <div class="nav-a">
          <router-link to="/Active1-app" class="a">激活校园ID</router-link>
          <a url="/" class="nav-a-center" />
          <router-link to="/Reset" class="a">忘记密码？</router-link>
        </div>
        <a ref="nav" url="/" class="record" @click="login()">立即登录</a>
      </div>
    </div>
  </div>
</template>

<script>
import { casLogin } from '../../api';
import { http } from '../../util/validate';
export default {
  data() {
    return {
      mima: '',
      account: '',
      sn: '',
      url: '',
      client_id: '',
      show: false,
      show2: false,
      screenWidth: document.body.clientHeight,
      msgshow: false,
      msg: '',
      mimashow: true
    };
  },
  mounted() {
    this.cutOut();
  },
  updated() {
    this.show = !this.account;
    this.show2 = !this.mima;
    if (!this.mima) this.msgshow = false;
    // console.log(this.show2);
  },
  methods: {
    showmima() {
      this.mimashow = !this.mimashow;
      let input = document.getElementById('mima');
      if (input.type == 'password') {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
    },
    cutOut() {
      let query = window.location.href;
      let login = query.split('UserLogin')[1];
      if (login === '') {
        let redirectUrl = encodeURIComponent(http() + '/user/loginOut');
        window.location.href =
          'https://portal.jsu.edu.cn/oauth2/logout?client_id=Bz6n8VwRQRG43tVxrWVxPw&response_type=code' +
          '&redirect_uri=' +
          redirectUrl;
      } else {
        this.url = query.split('redirect_uri=')[1];
        this.client_id = query.split('client_id=')[1].split('&')[0];
        this.sn = query.split('sn=')[1].split('&')[0];
        if (
          this.url === '' ||
          this.url === undefined ||
          this.client_id === '' ||
          this.client_id === undefined ||
          this.sn === '' ||
          this.sn === undefined
        ) {
          let redirectUrl = encodeURIComponent(http() + '/user/loginOut');
          window.location.href =
            'https://portal.jsu.edu.cn/oauth2/logout?client_id=Bz6n8VwRQRG43tVxrWVxPw&response_type=code' +
            '&redirect_uri=' +
            redirectUrl;
        }
      }
    },
    login() {
      this.show = !this.account;
      this.show2 = !this.mima;
      this.msgshow = !!this.mima;
      casLogin({
        account: this.account,
        belongClientId: this.client_id,
        redirect_uri: this.url,
        sn: this.sn,
        ticket: this.mima,
        type: 'pwd'
      }).then((res) => {
        if (res.data.code === 200) {
          console.log(res, 'res');
          window.location.href = res.data.data;
          this.msgshow = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    }
  }
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
@media screen and(-ms-high-contrast:active), (-ms-high-contrast: none) {
  /*兼容IE11*/
  span {
    position: absolute;
    width: 50vw;
    left: 11vw;
  }
}
input {
  outline: none;
}
.container {
  width: 100vw;
  height: 178vw;
  background-image: url("../../static/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 88.4vw;
  height: 74.53vw;
  border-radius: 1.33vw;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
  margin-top: 19.33vw;
}

.div_logo {
  width: 100vw;
  height: 32.8vw;
  text-align: center;
  background-color: #bf3238;
}

.logo {
  height: 17.8vw;
  width: 49.2vw;
  margin-top: 7.5vw;
}

.main-content {
  width: 79vw;
  height: 55.5vw;
  margin-top: 8.53vw;
  position: relative;
}

.input-div {
  position: relative;
}

.input-div #mimaeye {
  position: absolute;
  left: 70vw;
}

/* 输入提示消息 */
.input-div span {
  color: red;
  position: absolute;
  top: 12vw;
  font-size: 3.2vw;
}

.input-div img {
  width: 5.3vw;
  height: 5.3vw;
  position: absolute;
  left: 2.93vw;
  top: 2.937vw;
}

.input-div hr {
  position: absolute;
  top: 3.74vw;
  left: 10.53vw;
  height: 3.56vw;
  width: 0.21vw;
  background-color: #da251c;
  border: none;
}

input {
  width: 65.4vw;
  height: 10.68vw;
  margin-bottom: 5vw;
  border: 1px solid #bfbdbc;
  border-radius: 6.94pt;
  background-color: #fff;
  font-size: 3.2vw;
  padding-left: 13.6vw;
  color: #505050;
}

input:hover {
  border-color: #da251c;
}

.nav-a {
  display: flex;
  margin-top: 2vw;
}

.nav-a .a {
  color: #c71921;
  font-size: 3.2vw;
  margin-left: 1.06vw;
  text-decoration: none;
  cursor: pointer;
}

.nav-a-center {
  flex: 1;
}

.record {
  display: block;
  width: 63.3vw;
  height: 10.68vw;
  background-image: url("../../static/denglu.png");
  background-size: 100% 100%;
  font-size: 4vw;
  color: #fff;
  text-align: center;
  line-height: 10.68vw;
  margin-left: 7.7vw;
  cursor: pointer;
  margin-top: 7vw;
}
</style>
