import Vue from 'vue';
import VueRouter from 'vue-router';
import UserLogin from '../views/login/UserLogin';

Vue.use(VueRouter);

const routes = [
  {
    /* 首页*/
    /* 暂时登录页*/
    path: '/UserLogin',
    name: 'UserLogin',
    component: UserLogin
  },
  {
    /* pc-app-激活ID*/
    path: '/UserActivateId',
    name: 'UserActivateId',
    component: () => import('../views/login/UserActivateId')
  },
  {
    /* 登录页*/
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login')
  },
  {
    /* 激活校园ID*/
    path: '/activateId',
    name: 'activateId',
    component: () => import('../views/login/ActivateId')
  },
  {
    /* 忘记密码*/
    path: '/recoverPwd',
    name: 'recoverPwd',
    component: () => import('../views/login/RecoverPwd')
  },
  {
    /* 个人中心*/
    path: '/userPersonalCenter',
    name: 'userPersonalCenter',
    component: () => import('../views/main/personal/UserPersonalCenter')
  },
  {
    /* 个人中心pc*/
    path: '/personalCenter',
    name: 'personalCenter',
    component: () => import('../views/main/personal/PersonalCenter')
  },
  {
    /* 登录页app*/
    path: '/login-app',
    name: 'login-app',
    component: () => import('../views/uni_login/Login-app')
  },
  {
    /* 激活校园(身份验证)app*/
    path: '/Active1-app',
    name: 'Active1-app',
    component: () => import('../views/uni_login/Active1-app')
  },
  {
    /* 激活校园(短信验证并激活)app*/
    path: '/Active2-app/:code/:workNo/:name/:ids',
    name: 'Active2-app',
    props: true,
    component: () => import('../views/uni_login/Active2-app')
  },
  {
    /* 激活sucess-app*/
    path: '/Active_sucess',
    name: 'Active_sucess',
    component: () => import('../views/uni_login/Active_sucess')
  },
  {
    /* 激活fail-app*/
    path: '/Active_fail',
    name: 'Active_fail',
    component: () => import('../views/uni_login/Active_fail')
  },
  {
    /* 忘记密码-app*/
    path: '/Reset',
    name: 'Reset',
    component: () => import('../views/uni_login/Reset')
  },
  {
    /* 个人中心-app*/
    path: '/Me',
    name: 'Me',
    component: () => import('../views/main/uni_personal/Me')
  },
  {
    /* 个人中心修改手机号-app*/
    path: '/Updatephone-app',
    name: 'Updatephone-app',
    component: () => import('../views/main/uni_personal/Updatephone-app')
  }
];

const router = new VueRouter({
  // mode:"history",
  routes
});

export default router;
