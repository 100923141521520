import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from './store';

import ElementUI from 'element-ui';
import '../theme/index.css';
/* import 'element-ui/lib/theme-chalk/index.css';*/
Vue.use(ElementUI);

import './assets/iconfont/iconfont.css';

/* import echarts from "echarts";*/
/* import "echarts/map/js/china.js";*/
/* Vue.prototype.$echarts = echarts;*/

/* import $ from 'jquery';*/

/* import scroll from 'vue-seamless-scroll';
Vue.use(scroll);*/

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
