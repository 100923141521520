import axios from 'axios';
import { http } from '../util/validate';

import { getAES } from '../util/encryption';

let value = 'Bz6n8VwRQRG43tVxrWVxPw';

let https = http();

/* ============>>>个人中心<<<============*/
/*
 * 获取当前登录用户信息
 * /user
 * get
 * code=200查询成功，500获取失败
 */
export function user() {
  return axios({
    method: 'GET',
    url: https + '/user',
    headers: {
      token: getAES(value + new Date().getTime()),
      'x-requested-with': 'XMLHttpRequest'
    }
  });
}

/*
 * 校验验证码（更换手机号）
 * /user/checkSmsForUpdateMobile
 * get
 * code=200更新手机号成功，500验证码已失效
 */
export function checkSmsForUpdateMobile(params) {
  return axios({
    method: 'get',
    url: https + '/user/checkSmsForUpdateMobile',
    headers: {
      token: getAES(value + new Date().getTime()),
      'x-requested-with': 'XMLHttpRequest'
    },
    params
  });
}

/*
 *
 * /user/loginOut
 * get
 *
 */
export function loginOut() {
  return axios({
    method: 'get',
    url: https + '/user/loginOut',
    headers: {
      token: getAES(value + new Date().getTime()),
      'x-requested-with': 'XMLHttpRequest'
    }
  });
}

/*
 * 发送新手机的短信（更新手机号）
 * /user/sendSmsForUpdateMobile
 * get
 * code=200发送成功，404校验码校验失败,500此手机号已被绑定
 */
export function sendSmsForUpdateMobile(params) {
  return axios({
    method: 'get',
    url: https + '/user/sendSmsForUpdateMobile',
    headers: {
      token: getAES(value + new Date().getTime()),
      'x-requested-with': 'XMLHttpRequest'
    },
    params
  });
}

/* ============>>>激活<<<============*/
/*
 * 手机校验
 * 带上校验成功码，发送新手机的短信（激活）
 * /login/sendSmsForNew
 * get
 * code=200发送成功，404校验码校验失败,500此手机号已被绑定
 */
export function sendSmsForNew(params) {
  return axios({
    method: 'get',
    url: https + '/login/sendSmsForNew',
    headers: {
      token: getAES(value + new Date().getTime())
    },
    params
  });
}
/**
 * 获取证件类型
 * @param {*} data
 * @returns
 */
export function getSomething(params) {
  return axios({
    method: 'get',
    url: https + '/login/getDictdata?type=certificateType',
    headers: {
      token: getAES(value + new Date().getTime())
    },
    params
  });
}

/*
 * 确认激活
 * 激活用户信息（激活）
 * /login/updateInfo
 * post
 * code=200激活成功，404校验码校验失败，500系统错误
 */
export function updateInfo(data) {
  return axios({
    method: 'post',
    url: https + '/login/updateInfo',
    headers: {
      token: getAES(value + new Date().getTime())
    },
    data
  });
}

/*
 * 身份验证
 * 校验身份证信息（激活）
 * /login/checkIdentity
 * post
 * code=200成功，返回校验成功码，500校验失败
 */
export function checkIdentity(data) {
  return axios({
    method: 'post',
    url: https + '/login/checkIdentity',
    headers: {
      token: getAES(value + new Date().getTime())
    },
    data
  });
}

/* ============>>>登录<<<============*/
/*
 * 用户登录
 * 校验成功返回跳转地址，失败会提示错误
 * /login/casLogin
 * post
 */
export function casLogin(data) {
  return axios({
    method: 'post',
    url: https + '/login/casLogin',
    headers: {
      token: getAES(value + new Date().getTime())
    },
    data
  });
}

/*
 * 获取通知
 * 为空不显示，有数据就显示
 * /login/getNotice
 *
 */
export function getNotice(params) {
  return axios({
    method: 'get',
    url: https + '/login/getNotice',
    headers: {
      token: getAES(value + new Date().getTime())
    },
    params
  });
}
/* ============>>>忘记密码<<<============*/
/*
 * 下一步校验
 * 校验验证码（找回密码）
 * login/checkSms
 * get
 * code=200通过,返回短信校验成功码，500已失效
 */
export function checkSms(params) {
  return axios({
    method: 'get',
    url: https + '/login/checkSms',
    headers: {
      token: getAES(value + new Date().getTime())
    },
    params
  });
}

/*
 * 手机校验
 * 获取已绑定手机号的验证码（找回密码）
 * /login/sendSms
 * get
 * code=200发送成功，404此手机号未绑定用户,500返回调用失败
 */
export function sendSms(params) {
  return axios({
    method: 'get',
    url: https + '/login/sendSms',
    headers: {
      token: getAES(value + new Date().getTime())
    },
    params
  });
}

/*
 * 新密码提交
 * 带上短信校验成功码，更新密码（找回密码）
 * /login/updatePwd
 * get
 * code=200成功，500非法访问
 */
export function updatePwd(params) {
  return axios({
    method: 'get',
    url: https + '/login/updatePwd',
    headers: {
      token: getAES(value + new Date().getTime())
    },
    params
  });
}

/*
 * 检查输入的工号（找回密码）
 * /login/checkWorkNo
 * get
 * code=200成功，500非法访问
 */
export function checkWorkNo(params) {
  return axios({
    method: 'get',
    url: https + '/login/checkWorkNo',
    headers: {
      token: getAES(value + new Date().getTime())
    },
    params
  });
}
