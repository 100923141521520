<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    // 这里存放数据",
    return {};
  }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
}
/* 修改表格的滚动条*/
.el-table__body-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
/* 表格滚动条的滑块*/
.el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(192,51,56,0.8);
    border-radius: 3px;
}
/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 10px;/*竖向*/
  height: 10px;/*横向*/
}
/*滚动条的滑块*/
::-webkit-scrollbar-thumb {
  background-color: rgba(192,51,56,0.8);
  border-radius: 3px;
}

</style>
