<template>
  <div id="userLogin">
    <login_app v-if="isShow2" class="uniApp_login" />
    <login v-if="isShow" class="pc_login" />
  </div>
</template>

<script>
import Login from '../login/Login';
import Login_app from '../uni_login/Login-app';
import { http } from '../../util/validate';
export default {
  // import引入的组件需要注入到对象中才能使用",
  components: { Login, Login_app },
  data() {
    // 这里存放数据",
    return {
      isShow: false,
      isShow2: false
    };
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {

  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    // setInterval(this.toObtainTheWidth, 1000);
  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {
    this.cutOut();
  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
  },
  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
  // 方法集合",
  methods: {
    cutOut() {
      let query = window.location.href;
      // alert(query.indexOf("UserLogin") != -1 && query.indexOf("redirect_uri") != -1 && query.indexOf("client_id") != -1 && query.indexOf("sn") != -1);
      // debugger;
      if (query.indexOf('UserLogin') != -1 && query.indexOf('redirect_uri') != -1 && query.indexOf('client_id') != -1 && query.indexOf('sn') != -1) {
        this.toObtainTheWidth();
      } else {
        let redirectUrl = encodeURIComponent(http() + '/user/loginOut');
        window.location.href = 'https://portal.jsu.edu.cn/oauth2/logout?client_id=Bz6n8VwRQRG43tVxrWVxPw&response_type=code' +
            '&redirect_uri=' + redirectUrl;
      }
    },
    toObtainTheWidth() {
      let nowWidth = window.innerWidth;
      if (nowWidth > 1000) {
        this.isShow = true;
        this.isShow2 = false;
      } else if (nowWidth < 1000) {
        this.isShow = false;
        this.isShow2 = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
  #userLogin {
    width: 100%;
    height: 100%;
    display: flex;
  }

</style>
