<template>
  <div id="login">
    <div v-if="is360Img" class="img" />
    <div class="login-img" />
    <div class="login-blank" />
    <div class="login-hint">
      <el-drawer
        :title="titleMessage"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <div
          style="color:rgba(192,51,56,0.8); font-size: 28px; margin: 0px auto; width: 80%; height: 90%;overflow: auto;"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {{ reminder }}</div>
      </el-drawer>
    </div>
    <div class="login-frame">
      <div class="login-frame-left">
        <div class="login-frame-left-below">
          <div class="login-frame-left-below-div1">
            <el-col :span="16" style="margin: 0px auto;">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
              >
                <div class="login-frame-left-below-div1-label">统一身份认证</div>
                <el-tabs v-model="activeName" class="fontColor">
                  <el-tab-pane
                    name="loginPwd"
                  ><!--label="密码登录"-->
                    <el-col :span="24" style="margin: 0px auto;">
                      <div class="login-frame-left-below-div1-user">
                        <el-form-item prop="account">
                          <el-input
                            v-model="ruleForm.account"
                            type="text"
                            placeholder="学工号"
                            prefix-icon="iconfont icon-user"
                            clearable
                            @keypress.native.enter="submitForm('ruleForm')"
                          />
                        </el-form-item>
                      </div>
                      <div class="login-frame-left-below-div1-password">
                        <el-form-item prop="ticket">
                          <el-input
                            v-model="ruleForm.ticket"
                            type="password"
                            show-password
                            placeholder="密码"
                            prefix-icon="iconfont icon-icon-mima"
                            @keypress.native.enter="submitForm('ruleForm')"
                          />
                        </el-form-item>
                      </div>
                    </el-col>
                  </el-tab-pane>

                  <!--手机登录-->
                  <!--<el-tab-pane
                    label="手机登录"
                    name="loginNote"
                    :disabled="true"
                  >
                    <el-col :span="24" style="margin: 0px auto;">
                      <div class="login-frame-left-below-div1-user">
                        <el-input
                          placeholder="学工号"
                          prefix-icon="iconfont icon-user"
                          clearable
                        >
                        </el-input>
                      </div>
                      <div class="login-frame-left-below-div1-password">
                        <el-input
                          placeholder="密码"
                          prefix-icon="iconfont icon-icon-mima"
                          show-password
                        >
                        </el-input>
                      </div>
                    </el-col>
                  </el-tab-pane>-->
                </el-tabs>
              </el-form>
              <el-col :span="24" style="margin: 0px auto;">
                <div class="login-frame-left-below-div1-rest">
                  <label class="login-frame-left-below-div1-rest-label1">
                    <el-checkbox v-model="pasChecked" style="color: rgba(43,42,51,0.5);font-weight: 600;">记住密码</el-checkbox>
                  </label>
                  <router-link to="/recoverPwd">
                    <label class="login-frame-left-below-div1-rest-label2 touch">忘记密码?</label>
                  </router-link>
                </div>
              </el-col>
            </el-col>
          </div>
          <div class="login-frame-left-below-div2">
            <el-col :span="16" style="margin: 0px auto;">
              <el-button
                type="primary"
                :loading="loading"
                style="width: 100%; height: 100%; font-size: 16px; font-weight: 600"
                @click="submitForm('ruleForm')"
              >
                立即登录
              </el-button>
            </el-col>
          </div>
          <!--<div class="login-frame-left-below-div5">
            <label class="login-frame-left-below-div5-label1"></label>
            <label class="login-frame-left-below-div5-label2">
              第三方授权登录
            </label>
            <label class="login-frame-left-below-div5-label3"></label>
            <i class="login-frame-left-below-div5-labelQQ iconfont icon-QQ" @click="clickQQ"/>
            <i class="login-frame-left-below-div5-labelWX iconfont icon-weixin" @click="clickWX"/>
          </div>-->
          <div class="login-frame-left-below-div3">
            <span class="login-frame-left-below-div3-span1">温馨提示：</span>
          </div>
          <div class="login-frame-left-below-div4">
            <span class="login-frame-left-below-div4-span2">
              1. 首次登录必须先
              <router-link to="/activateId">
                <label class="login-frame-left-below-div4-span2-label1">
                  激活校园ID
                </label>
              </router-link>
              ，并且按提示绑定手机和邮箱。
            </span>
          </div>
          <div class="login-frame-left-below-div5">
            <span class="login-frame-left-below-div5-span2">
              2. 建议使用
              <a
                href="https://nic.jsu.edu.cn/info/1009/1262.htm"
                class="login-frame-left-below-div5-span2-label1"
                target="_blank"
              >
                谷歌、火狐。
              </a>
              360浏览器请切换成
              <label
                class="login-frame-left-below-div5-span2-label1"
                @mouseover="mouseOver"
                @mouseleave="mouseLeave"
              >
                极速模式
              </label>
            </span>
          </div>
          <div class="login-frame-left-below-div5">
            <span class="login-frame-left-below-div5-span2">3. 企业微信、校园ID密码、密保手机等
              <a
                href="https://nic.jsu.edu.cn/info/1009/1362.htm#ID"
                class="login-frame-left-below-div5-span2-label1"
                target="_blank"
              >
                疑难问题
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="login-top">
      <div class="login-top-img" />
    </div>
    <div class="login-below">
      <span class="login-frame-left-below-div4-span1">版权所有 © 2016 吉首大学 中国工商银行湘西分行</span>
    </div>
  </div>
</template>

<script>
/*
  *api
  */
import { casLogin, getNotice } from '../../api';
import { getAES, getDAes } from '../../util/encryption';
export default {
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  data() {
    // 这里存放数据",
    return {
      titleMessage: '',
      reminder: '',
      drawer: false,
      direction: 'ltr',
      is360Img: false,
      pasChecked: false,
      sn: '',
      url: '',
      client_id: '',
      activeName: 'loginPwd',
      /* 表单*/
      ruleForm: {
        account: '',
        ticket: ''
      },
      loading: false,
      /* 验证*/
      rules: {
        account: [
          {
            required: true,
            message: '请输入学工号',
            trigger: 'change'
          }
        ],
        ticket: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change'
          }
        ]
      }
    };
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {

  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
    // 截取 sn, url 的值
    this.notice();
    this.cutOut();
    this.getCookie();
    this.changeChecked();
  },
  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
  // 方法集合",
  methods: {
    // 获取通知
    notice() {
      getNotice().then(res => {
        if (res.status === 200) {
          // console.log(res.data,"res");
          if (res.data.msg !== '' && res.data.data !== '') {
            this.titleMessage = res.data.msg;
            this.reminder = res.data.data;
            this.drawer = true;
          } else {
            this.drawer = false;
          }
        }
      });
    },
    handleClose(done) {
      done();
      /* this.$confirm('确认关闭？')
          .then(_ => {
          })
          .catch(_ => {});*/
    },
    // 验证是否自动登录
    changeChecked() {
      if (this.ruleForm.account !== '' && this.ruleForm.ticket !== '') {
        this.pasChecked = true;
        /* casLogin({
            account: this.ruleForm.account,
            redirect_uri: this.url,
            belongClientId :this.client_id,
            sn: this.sn,
            ticket:this.ruleForm.ticket,
            type:"pwd"
          }).then(res => {
            if(res.data.code === 200) {
              //debugger;
              //console.log(res.data.data,"res.data.data");
              window.location.href = res.data.data;
              this.loading = false;
            } else {
              this.$message.error(res.data.msg);
              this.loading = false;
            }
          });*/
      } else {
        this.pasChecked = false;
      }
    },

    // 显示360图片
    mouseOver() {
      this.is360Img = true;
    },
    // 关闭360图片
    mouseLeave() {
      this.is360Img = false;
    },
    // 获取参数
    cutOut() {
      let query = window.location.href;
      this.url = query.split('redirect_uri=')[1];
      this.client_id = query.split('client_id=')[1].split('&')[0];
      this.sn = query.split('sn=')[1].split('&')[0];
    },

    /*
      * 用户登录
      * 校验成功返回跳转地址，失败会提示错误
      * /login/casLogin
      * post
      * */
    submitForm(formName) {
      this.loading = true;
      let that = this;
      let name = that.ruleForm.account;
      let pass = that.ruleForm.ticket;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          casLogin({
            account: that.ruleForm.account,
            redirect_uri: that.url,
            belongClientId: that.client_id,
            sn: that.sn,
            ticket: that.ruleForm.ticket,
            type: 'pwd'
          }).then(res => {
            if (res.data.code === 200) {
              if (this.pasChecked === true) {
                this.setCookie(getAES(name), getAES(pass), 3);
              } else {
                this.clearCookit();
              }
              // debugger;
              // console.log(res.data.data,"res.data.data");
              window.location.href = res.data.data;
              that.loading = false;
            } else {
              that.$message.error(res.data.msg);
              that.loading = false;
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    // 设置cookie
    setCookie(c_name, c_pwd, exdays) {
      let exdate = new Date();// 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays);// 保存的天数
      // 字符串拼接cookie
      window.document.cookie = 'un' + '=' + c_name + ';path=/;expires=' + exdate.toGMTString();
      window.document.cookie = 'up1' + '=' + c_pwd + ';path=/;expires=' + exdate.toGMTString();
    },
    // 读取cookie
    getCookie() {
      let arr = document.cookie.split('; ');// 这里显示的格式需要切割一下自己可输出看下
      for (let i = 0; i < arr.length; i++) {
        let arr2 = arr[i].split('=');// 再次切割
        // 判断查找相对应的值
        if (arr2[0] === 'un') {
          this.ruleForm.account = getDAes(arr2[1]);// 保存到保存数据的地方
        } else if (arr2[0] === 'up1') {
          this.ruleForm.ticket = getDAes(arr2[1]);
        }
      }
    },
    // 清楚cookie
    clearCookit() {
      this.setCookie('', '', -1);
    }
  }
};
</script>

<style scoped lang="scss">
  a{
    text-decoration:none;
  }
  .img {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 40%;
    z-index: 1000;
    background-image: url("../../assets/img/login/360.jpg");
    background-repeat: no-repeat;
    background-position:center;
    background-size:100% 100%;
  }
  /deep/.el-drawer__body {
    overflow: hidden;
  }
  /deep/.el-drawer__header>:first-child {
    font-size: 35px;
    color: black;
    text-align: center;
  }
  /deep/.el-tabs__item {
    font-size: 18px !important;
    width: 160px;
    height: 0px;
    text-align: center;
  }
  /*!deep/.el-tabs--left .el-tabs__item.is-left {
    text-align: center;
  }*/
  /*/deep/.el-tabs__item.is-active {
    background-color: rgba(199,25,33,0.1);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  /deep/.el-input--prefix .el-input__inner {
    background-color: rgba(43,42,51,0.01);
  }*/

  .touch {
    color: rgba(43,42,51,0.5);
    cursor: pointer;//鼠标变手指
  }
#login {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  //-webkit-filter: blur(1px);
  //-moz-filter: blur(15px);
  //-o-filter: blur(15px);
  //-ms-filter: blur(15px);
  //filter: blur(15px);

  .login-img {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1000;
    background-image: url("../../assets/img/login/beijing2.png");
    background-repeat: no-repeat;
    background-position:center;
    background-size:100% 100%;
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
  }
  .login-blank {
    position: absolute;
    top: 58%;
    left: 50%;
    width: 620px;
    height: 510px;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,0.6);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .14);
  }
  .login-frame {
    position: absolute;
    top: 58%;
    left: 50%;
    width: 560px;
    height: 450px;
    display: flex;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);

    &-left {
      height: 100%;
      width: 100%;

      &-below {
        width: 100%;
        height: 430px;
        margin: 20px auto;

        &-div1 {
          width: 100%;
          height: 240px;
          display: flex;
          flex-wrap: wrap;

          &-label {
            width: 160px;
            height: 50px;
            font-size: 26px;
            font-weight: 900;
            line-height: 50px;
            color: rgba(192,51,56,0.8);

          }
          &-user {
            margin-top: 10px;
            width: 100%;
            height: 50px;

          }
          &-password {
            margin-top: 10px;
            width: 100%;
            height: 58px;

          }
          &-rest {
            width: 100%;
            height: 20px;
            position: relative;
            font-size: 13px;
            font-weight: 600;

            &-label1 {
              position: absolute;
              left: 10px;
              bottom: 0px;
            }
            &-label2 {
              position: absolute;
              right: 10px;
              bottom: 0px;
            }
          }
        }
        &-div2 {
          margin-top: 20px;
          margin-bottom: 15px;
          width: 100%;
          height: 50px;
          display: flex;
        }

        /*&-div5 {
          width: 100%;
          height: 80px;
          margin-top: 10px;
          position: relative;
          display: flex;

          &-label1 {
            border: 0.5px solid rgba(43,42,51,0.3);
            width: 20%;
            position: absolute;
            top: 15px;
            left: 20%;
          }
          &-label2 {
            position: absolute;
            width: 150px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            top: 0%;
            left: 50%;
            color: rgba(43,42,51,0.7);
            transform: translate(-50%, 0%);
          }
          &-label3 {
            border: 0.5px solid rgba(43,42,51,0.3);
            width: 20%;
            position: absolute;
            top: 15px;
            right: 20%;
          }
          &-labelQQ {
            width: 40px;
            height: 40px;
            color: rgba(43,42,51,0.7);
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            position: absolute;
            top: 65%;
            left: 45%;
            transform: translate(-50%, -50%);
            cursor: pointer;//鼠标变手指
          }
          &-labelWX {
            width: 40px;
            height: 40px;
            color: rgba(43,42,51,0.7);
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            position: absolute;
            top: 65%;
            right: 45%;
            transform: translate(50%, -50%);
            cursor: pointer;//鼠标变手指
          }
        }*/
        &-div3 {
          margin: 10px auto;
          width: 360px;
          height: 20px;
          line-height: 30px;
          font-size: 11px;
          font-weight: 900;
          color: rgba(43,42,51,0.8);
        }
        &-div4 {
          margin: 0px auto;
          width: 360px;
          height: 25px;
          font-size: 10px;
          font-weight: 600;
          color: rgba(43,42,51,0.5);
          &-span2 {
            display: flex;
            &-label1 {
              margin-left: 4px;
              font-size: 12px;
              color: rgba(210,25,33,0.5);
              text-decoration: underline;
              cursor: pointer;//鼠标变手指
            }
          }
        }
        &-div5 {
          margin: 0px auto;
          width: 360px;
          height: 25px;
          font-size: 10px;
          font-weight: 600;
          color: rgba(43,42,51,0.5);
          &-span2 {
            display: flex;
            &-label1 {
              margin-left: 4px;
              font-size: 12px;
              color: rgba(210,25,33,0.5);
              text-decoration: underline;
              cursor: pointer;//鼠标变手指
            }
          }
        }
      }
    }

  }
  .login-top {
    width: 100%;
    height: 129px;
    background-color: #c03338;
    display: flex;

    &-img {
      width: 300px;
      height: 116px;
      margin: 7px auto;
      background-image: url("../../assets/img/login/jdlogo.png");
      background-position:center;
      background-size:100% 100%;
    }
  }
  .login-below {
    height: 40px;
    line-height: 40px;
    color: rgba(255,255,255,1);
    position: absolute;
    bottom: 1%;
    left: 50%;
    font-size: 13px;
    transform: translate(-50%, 0%);
  }
}
</style>
