let CryptoJS = require('crypto-js');

/* 加密*/
function getAesString(data, key, iv) {
  let encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}
/* 解密*/
function getDAesString(encrypted, key, iv) {
  let decrypted = CryptoJS.AES.decrypt(encrypted, CryptoJS.enc.Utf8.parse(key),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function getAES(data) { // 加密
  let key = 'rKLXUF-KT_Gm6qmu'; // 密钥
  let iv = 'rKLXUF-KT_Gm6qmu';
  let encrypted = getAesString(data, key, iv); // 密文
  let encrypted1 = CryptoJS.enc.Utf8.parse(encrypted);
  return encrypted;
}

export function getDAes(data) { // 解密
  let key = 'rKLXUF-KT_Gm6qmu'; // 密钥
  let iv = 'rKLXUF-KT_Gm6qmu';
  let decryptedStr = getDAesString(data, key, iv);
  return decryptedStr;
}
