/* 判断手机格式*/
export function isPhone(number) {
  return /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(
    number
  );
}

/* 是否存在空格*/
export function isNewPwdBlank(number) {
  return !/\s/g.test(number);
}
/* 是否存在中文*/
export function isNewPwdChinese(number) {
  return !/[\u4E00-\u9FA5]/g.test(number);
}
/* 判断密码强度弱*/
export function isNewPwdStrengthWeak(number) {
  return /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,16}$/.test(number);
}
/* 判断密码强度中*/
export function isNewPwdStrength(number) {
  return /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-]).{8,16}$/.test(
    number
  );
}
/* 判断密码强度强*/
export function isNewPwdStrengthStronger(number) {
  return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-]).{8,16}$/.test(
    number
  );
}
/* 判断邮箱格式*/
export function isEmail(number) {
  return /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/.test(
    number
  );
}
/** 判断身份证格式
 * @param {*} number
 * @returns
 */
export function isIdentity(number) {
  // /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9]|10|20|30|31|)\d{2}$/ : 15位
  return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
    number
  );
}

/* 判断性别*/
export function isSex(number) {
  let value = '';
  if (number === '0') {
    value = '待完善';
  } else if (number === '1') {
    value = '男';
  } else if (number === '2') {
    value = '女';
  }
  return value;
}
/* 判断身份类型*/
export function isCertificateType(val) {
  let value = '';
  if (val === 'idcard') {
    value = '身份证';
  } else if (val === 'passport') {
    value = '护照';
  } else if (val === 'militaryId') {
    value = '军官证';
  } else if (val === 'other') {
    value = '其他';
  }
  return value;
}

export function http() {
  // return "http://10.8.117.234:8081";
  return 'https://pass.jsu.edu.cn';
}
